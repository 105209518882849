<template>
  <div id="excel">
    <p class="MsoNormal" align="center" style="text-align:center;">
      <span
          style="font-family:方正小标宋_GBK;line-height:200%;font-size:22.0000pt;">&nbsp;<span>食品快速检测报告单</span></span><span
        style="font-family:方正小标宋_GBK;line-height:200%;font-size:22.0000pt;"></span>
    </p>
    <p class="15" align="center"
       style="margin-left:0.0000pt;text-indent:0.0000pt;text-align:center;vertical-align:baseline;">
      （编号：
        <span style="font-family:方正仿宋_GBK;font-size:12pt;" @click="titleEditable($event)">
          {{ data.sample_no }}
        </span>
      )
      <span style="font-family:方正仿宋_GBK;font-size:12pt;"></span>
    </p>
    <p class="15" align="center"
       style="margin-left:0.0000pt;text-indent:0.0000pt;text-align:center;vertical-align:baseline;">
      <span style="font-family:方正仿宋_GBK;font-size:12pt;">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;
        <span>检测时间：{{ new Date(data.check_time).toLocaleDateString('zh-CN', { year: 'numeric', month: 'long', day: 'numeric' }) }}</span></span><span
        style="font-family:方正仿宋_GBK;font-size:12pt;"></span>
    </p>
    <div align="center">
      <table class="MsoNormalTable" border="1" cellspacing="0"
             style="border-collapse:collapse;width:461.6500pt;border:none;">
        <tbody>
        <tr>
          <td width="95" valign="center" rowspan="6" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">被检测</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;">单位</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;">（</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;">或摊位</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;">）</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">基本情况</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="136" valign="center" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">名称</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="383" valign="center" colspan="3" style="border:1.0000pt solid windowtext;" @click="makeEditable($event)">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">{{ data.send_company }}
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="136" valign="center" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">地址或摊位信息</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="383" valign="center" colspan="3" style="border:1.0000pt solid windowtext;" @click="makeEditable($event)">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="136" valign="center" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">统一社会信用代码
              <br>
                或身份证号码
              </span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="383" valign="center" colspan="3" style="border:1.0000pt solid windowtext;" @click="makeEditable($event)">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="136" valign="center" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">售货人姓名</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="383" valign="center" colspan="3" style="border:1.0000pt solid windowtext;" @click="makeEditable($event)">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="136" valign="center" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">联系</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;">电话</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="383" valign="center" colspan="3" style="border:1.0000pt solid windowtext;" @click="makeEditable($event)">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="136" valign="center" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">采样场所</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="383" valign="center" colspan="3" style="border: 1.0000pt solid windowtext;" id="marketRadio">
              <p class="MsoNormal" align="center" style="text-align:center;">
                <span style="cursor: pointer;" @click="placeOption('农批市场')">
                  <span v-if="place === '农批市场'">☑</span>
                  <span v-else>▢</span>
                  &nbsp;农批市场
                </span>
                <span style="cursor: pointer;" @click="placeOption('农贸市场')">
                  <span v-if="place === '农贸市场'">☑</span>
                  <span v-else>▢</span>
                  &nbsp;农贸市场
                </span>
                <span style="cursor: pointer;" @click="placeOption('大型和生鲜连锁超市')">
                  <span v-if="place === '大型和生鲜连锁超市'">☑</span>
                  <span v-else>▢</span>
                  &nbsp;大型和生鲜连锁超市
                </span>
              </p>
              <p class="MsoNormal" align="center" style="text-align:center;">
                <span style="cursor: pointer;" @click="placeOption('电商仓储')">
                  <span v-if="place === '电商仓储'">☑</span>
                  <span v-else>▢</span>
                  &nbsp;电商仓储
                </span>
                <span style="cursor: pointer;" @click="placeOption('其他场所')">
                  <span v-if="place === '其他场所'">☑</span>
                  <span v-else>▢</span>
                  &nbsp;其他场所
                </span>
              </p>
          </td>

        </tr>
        <tr>
          <td width="95" valign="center" rowspan="4" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">样品情况</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="136" valign="center" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">样品</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;">类别</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="130" valign="center" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal"  align="justify" style="text-align:justify;">
              <span style="cursor: pointer;" @click="sampleOption('蔬菜')">
                <span v-if="sample === '蔬菜'">☑</span>
                <span v-else>▢</span>
                &nbsp;蔬菜&nbsp;
              </span>
              <span style="cursor: pointer;" @click="sampleOption('水果')">
                <span v-if="sample === '水果'">☑</span>
                <span v-else>▢</span>
                &nbsp;水果
              </span>
              <span style="cursor: pointer;" @click="sampleOption('水产品')">
                <span v-if="sample === '水产品'">☑</span>
                <span v-else>▢</span>
                &nbsp;水产品
              </span>
              <span style="cursor: pointer;" @click="sampleOption('畜禽肉及副产品')">
                <span v-if="sample === '畜禽肉及副产品'">☑</span>
                <span v-else>▢</span>
                &nbsp;畜禽肉及副产品
              </span>
              <span style="cursor: pointer;" @click="sampleOption('其他食用农产品')">
                <span v-if="sample === '其他食用农产品'">☑</span>
                <span v-else>▢</span>
                &nbsp;其他食用农产品
              </span>
            </p>
          </td>
          <td width="135" valign="center" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">样品名称</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="117" valign="center" style="border:1.0000pt solid windowtext;" @click="makeEditable($event)">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">{{ data.sample }}</span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="136" valign="center" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">进货日期</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="130" valign="center" style="border:1.0000pt solid windowtext;" @click="makeEditable($event)">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">&nbsp;</span>
            </p>
          </td>
          <td width="135" valign="center" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">进货量</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;">（公斤）</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="117" valign="center" style="border:1.0000pt solid windowtext;" @click="makeEditable($event)">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="136" valign="center" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">单价（元）</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="130" valign="center" style="border:1.0000pt solid windowtext;" @click="makeEditable($event)">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">&nbsp;</span>
            </p>
          </td>
          <td width="135" valign="center" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">是否随附承诺
              <br>
                达标合格证
              </span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="117" valign="center" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="cursor: pointer;" @click="qualifiedOption('是')">
                <span v-if="qualified === '是'">☑</span>
                <span v-else>▢</span>
                &nbsp;是
              </span>
              <span style="cursor: pointer;" @click="qualifiedOption('否')">
                <span v-if="qualified === '否'">☑</span>
                <span v-else>▢</span>
                &nbsp;否
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="136" valign="center" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">检测用样品数量</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;">（公斤）</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="130" valign="center" style="border:1.0000pt solid windowtext;" @click="makeEditable($event)">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">&nbsp;</span>
            </p>
          </td>
          <td width="135" valign="center" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">备份用样品数量</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;">（公斤）</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="117" valign="center" style="border:1.0000pt solid windowtext;" @click="makeEditable($event)">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="95" valign="center" rowspan="4" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">检测结果</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="136" valign="center" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">样品</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;">编号</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="130" valign="center" style="border:1.0000pt solid windowtext;" @click="makeEditable($event)">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">&nbsp;</span>
            </p>
          </td>
          <td width="135" valign="center" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">检测项目 </span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="117" valign="center" style="border:1.0000pt solid windowtext;" @click="makeEditable($event)">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">&nbsp{{ data.check_item }}</span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="136" valign="center" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">标准值</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="130" valign="center" style="border:1.0000pt solid windowtext;" @click="makeEditable($event)">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="135" valign="center" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">检测结果值</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="117" valign="center" style="border:1.0000pt solid windowtext;" @click="makeEditable($event)">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="136" valign="center" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">检测结论</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="383" valign="center" colspan="3" style="border:1.0000pt solid windowtext;" @click="makeEditable($event)">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">合格</span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="136" valign="center" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">检测方式、依据</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
          <td width="383" valign="center" colspan="3" style="border:1.0000pt solid windowtext;" @click="makeEditable($event)">
            <p class="MsoNormal" align="center" style="text-align:center;">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">&nbsp;{{ data.ref_info }}</span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="615" valign="top" colspan="5" style="border:1.0000pt solid windowtext;">
            <p class="MsoNormal">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">&nbsp;</span>
            </p>
            <p class="MsoNormal">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">检测</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;">单位(公章)：{{ data.company_name }}</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
            <p class="MsoNormal">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">&nbsp;</span>
            </p>
            <p class="MsoNormal">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">&nbsp;</span>
            </p>
            <p class="MsoNormal">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">检测员</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;">签名： </span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;审核人签名：</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
            <p class="MsoNormal">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">&nbsp;</span>
            </p>
            <p class="MsoNormal">
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">&nbsp;</span>
            </p>
            <p class="MsoNormal" style="text-indent:288.0000pt;">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span style="font-family:方正仿宋_GBK;font-size:12.0000pt;">日期：{{ new Date(data.check_time).toLocaleDateString('zh-CN', { year: 'numeric', month: 'long', day: 'numeric' }) }}</span><span
                style="font-family:方正仿宋_GBK;font-size:12.0000pt;"></span>
            </p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <p class="15" style="margin-left:0pt;text-indent:0pt;vertical-align:baseline;">
      <b><span style="font-family:方正仿宋_GBK;font-size:10.5pt;">&nbsp;</span></b><span
        style="font-family:方正小标宋_GBK;font-size:18pt;"></span>
    </p>
  </div>
</template>
<script>
import place from "@element-plus/icons-vue/dist/es/place.mjs";

export default {
  name: "DataDetail",
  props:{
    data:{}
  },
  computed: {
    result: () => {
      switch (this.data.check_result) {
        case "1":
          return "合格";
        case "2":
          return "不合格";
        default:
          return "待定";
      }
    }
  },
  methods:{
    place() {
      return place
    },
    makeEditable(event) {
      let td = event.target;
      td.classList.add('td-edit-text');
      var input = document.createElement("input");
      input.type = "text";
      input.value = td.innerText;
      input.style.width = (td.offsetWidth - 10) + "px";
      input.style.width = "90%";
      input.style.height = "20px";
      input.style.border = "1px solid transparent";
      input.style.outline = "none";
      input.style.textAlign = "center";
      td.innerHTML = "";
      td.appendChild(input);
      input.addEventListener("blur", () => {
        td.innerText = input.value;
        td.style.textAlign = "center";
      });
      input.focus();
      if (td.innerText.trim() !== "") {
        input.value = td.innerText;
      }
    },
    titleEditable(event) {
      let td = event.target;
      td.classList.add('td-edit-text');
      var input = document.createElement("input");
      input.type = "text";
      input.value = td.innerText;
      input.style.width = (td.offsetWidth - 10) + "px";
      input.style.width = "100px";
      input.style.height = "20px";
      input.style.border = "1px solid transparent";
      input.style.outline = "none";
      input.style.textAlign = "center";
      td.innerHTML = "";
      td.appendChild(input);
      input.addEventListener("blur", () => {
        td.innerText = input.value;
        td.style.textAlign = "center";
      });
      input.focus();
      if (td.innerText.trim() !== "") {
        input.value = td.innerText;
      }
    },

    placeOption(place) {
      this.place = place;
    },
    qualifiedOption(qualified) {
      this.qualified = qualified;
    },
    sampleOption(sample) {
      this.sample = sample;
    },
  },
  data() {
    return {
      qualified: '',
      sample: '',
      place: '',
    };
  },
};
</script>
<style lang="less" scoped>
</style>